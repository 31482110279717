import React from "react";
import Layout from "../components/_App/layout";
import Seo from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';

import AboutOne from "../assets/images/about-one.png";

const About = ({ pageContext: { lang, data }, location: { pathname } }) => (
    <Layout>
        <Seo
            title={data.seoTitle}
            lang={lang}
        /> 

        <Navbar
            currentPath={pathname}
            lang={lang}
        />

        <PageBanner pageTitle={data.bannerTitle} />

        <div className="about-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={AboutOne} alt={data.pageTitle} />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="section-title">
                                <h2>{data.pageTitle}</h2>
                                <div className="bar"></div>

                                <p>{data.text1}</p>
                            </div>

                            <p>{data.text2}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <Footer lang={lang} />
    </Layout>
)

export default About;
